import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Inview from 'components/Inview'

const Image = ({ filename, className = '' }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allImageSharp {
            nodes {
              gatsbyImageData
              parent {
                ... on File {
                  base
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const getNode = data.allImageSharp.nodes.find(
          (node) => node.parent?.base === filename
        )
        const image = getImage(getNode)
        if (!image) {
          return null
        }
        return (
          <Inview className={`fade-in ${className}`}>
            <GatsbyImage image={image} alt='' />
          </Inview>
        )
      }}
    />
  )
}
export default Image
