import React, { useState } from 'react'
import Image from 'components/Image'
import ButtonContact from 'components/ButtonContact'
import Inview from 'components/Inview'
import LinkWrap from 'components/LinkWrap'

const Component = () => {
  const [navIn, setNavIn] = useState(false)
  const navMenu = [
    {
      to: '/locations/',
      text: '店铺地图',
      addClass: 'menu_map',
    },
    {
      to: '/merchants/',
      text: '星选加盟',
      addClass: 'menu_join',
    },
    {
      to: '/customers/',
      text: '商户案例',
      addClass: 'menu_case',
    },
    {
      to: '/about/',
      text: '关于我们',
      addClass: 'menu_us',
    },
  ]

  const toggleNavIn = () => {
    setNavIn(!navIn)
    document.querySelector('html').classList.toggle('is-scroll-disabled-nav')
  }
  const closeNavIn = () => {
    setNavIn(false)
    document.querySelector('html').classList.remove('is-scroll-disabled-nav')
  }

  return (
    <header className='header'>
      <div className={`nav--holder bg--grey-2 ${navIn ? 'is-active' : ''}`}>
        <Inview>
          <nav className='nav container grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 fade-in'>
            <div className='col-span-2 md:col-span-2 lg:col-span-4'>
              <LinkWrap to='/' className='logo db' closeNavIn={closeNavIn}>
                <Image filename='logo.png' alt='logo' />
              </LinkWrap>
            </div>
            <ul className='nav--links hidden md:flex md:justify-center lg:justify-start col-span-4'>
              {navMenu.map((item, i) => (
                <li key={i}>
                  <LinkWrap
                    to={item.to}
                    className={`btn--text color--grey-5 header ${item.addClass}`}
                    closeNavIn={closeNavIn}
                  >
                    {item.text}
                  </LinkWrap>
                </li>
              ))}
            </ul>
            <ul className='nav--cta flex col-span-2 lg:col-span-4'>
              <li className='nav--hide hidden lg:flex'>
                <a href='tel:4008502727' className='btn--text color--grey-6'>
                  400-850-2727
                </a>
              </li>
              <li className='nav--hide hidden md:flex'>
                <ButtonContact addClass='menu_reserve' />
              </li>
              <li className='nav--icon--menu'>
                <a className={navIn ? 'is-active' : ''} onClick={toggleNavIn}>
                  <svg
                    version='1.1'
                    viewBox='0 0 12 11'
                    xmlns='http://www.w3.org/2000/svg'
                    x='0'
                    y='0'
                    className='icon--menu'
                  >
                    <rect
                      x='0'
                      y='4.1'
                      className='line-1'
                      width='12'
                      height='1.6'
                    ></rect>
                    <rect
                      x='4'
                      y='8.4'
                      className='line-2'
                      width='8'
                      height='1.6'
                    ></rect>
                    <rect
                      x='4'
                      y='0'
                      className='line-3'
                      width='8'
                      height='1.6'
                    ></rect>
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </Inview>
      </div>
      <div className='menu--modal'>
        <div
          className={`NavDropdown pt-20 md:pt-24 md:pb-16 lg:pb-24 bg--grey-2 ${
            navIn ? 'is-active' : ''
          }`}
        >
          <Inview className='container grid grid-cols-1 md:grid-cols-8 lg:grid-cols-12 align-end flex flex-col'>
            <div className='hidden md:block order-2 lg:order-1 col-span-8 lg:col-span-7 md:mt-20 lg:mt-0 fade-1 height-margin fade-in stagger-800'>
              <p className='eyebrow color--grey-6 mb-6 hide-height'>预约看铺</p>
              <p className='w-27 small color--grey-5 hide-height'>
                现在就与我们联络，预约厨房导览行程，
                或是进一步了解如何快速入驻星选闪电厨房。
              </p>
              <div className='nav--contact flex mt-12'>
                <div className='w-32 mr-12'>
                  <Image filename='微信公众号.png' />
                </div>
                <div className='pt-4'>
                  <p className='eyebrow color--grey-6 mb-2'>咨询电话</p>
                  <p className='small'>
                    <a href='tel:4008502727' className='menu--link'>
                      400-850-2727
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <nav className='order-1 lg:order-2 col-span-5 lg:pt-28'>
              <ul className='nav--large'>
                {navMenu.map((item, i) => (
                  <li
                    key={i}
                    className={`fade-in-down stagger-${100 * i + 400}`}
                  >
                    <LinkWrap to={item.to} closeNavIn={closeNavIn}>
                      {item.text}
                    </LinkWrap>
                  </li>
                ))}
              </ul>
            </nav>
            <div className='pt-6 md:pt-20 md:pt-24 order-3 col-span-4 lg:col-span-7 fade-1 lg:hidden height-margin fade-in stagger-1000'>
              <div className='flex md:hidden nav--contact'>
                <div>
                  <p className='eyebrow color--grey-6 mb-2'>咨询电话</p>
                  <p className='small'>
                    <a href='tel:4008502727' className='menu--link'>
                      400-850-2727
                    </a>
                  </p>
                  <div className='w-32 mt-8'>
                    <Image filename='微信公众号.png' />
                  </div>
                </div>
              </div>
            </div>
          </Inview>
        </div>
      </div>
      <div
        className={`modal--bg ${navIn ? 'is-active' : ''}`}
        onClick={toggleNavIn}
      />
    </header>
  )
}

export default Component
