export const scrollToContact = () => {
  const menu = document.querySelector('.nav--holder')
  menu.classList.add('stick')

  const block = document.querySelector('.BlockContact')
  const nav = document.querySelector('.nav')
  const blockY = block.offsetTop + nav.offsetHeight
  window.scrollTo({
    top: blockY,
    behavior: 'smooth',
  })

  setTimeout(() => menu.classList.remove('stick'), 2000)
}
