exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-merchants-js": () => import("./../../../src/pages/merchants.js" /* webpackChunkName: "component---src-pages-merchants-js" */),
  "component---src-templates-customer-js": () => import("./../../../src/templates/customer.js" /* webpackChunkName: "component---src-templates-customer-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-merchant-js": () => import("./../../../src/templates/merchant.js" /* webpackChunkName: "component---src-templates-merchant-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

