import React from 'react'
import { scrollToContact } from 'functions/utils.js'

const Component = ({ to, text, addClass = '' }) => {
  return (
    <a className={`btn--contact ${addClass}`} onClick={scrollToContact}>
      <span className='default'>预约看铺</span>
      <span className='mask'>
        <span className='hover'>
          预约看铺
          <span className='arrow arrow-bar is-bottom'></span>
        </span>
      </span>
    </a>
  )
}

export default Component
