import React from 'react'
import { Link } from 'gatsby'

const LinkWrap = ({ to, className, children, closeNavIn }) => {
  return (
    <Link
      to={to}
      className={className}
      onClick={closeNavIn}
      activeClassName='active'
      partiallyActive={true}
    >
      {children}
    </Link>
  )
}

export default LinkWrap
